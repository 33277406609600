//配置路由的地方
import Vue from 'vue'
import VueRouter from 'vue-router'
// import echarts from 'echarts'
// Vue.prototype.$echarts = echarts

//使用插件
Vue.use(VueRouter);

//引入路由组件
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import IframeBigsc from '../views/IframeBigsc.vue'
// import map from '../public/map/index.vue'

const routes = [
  {
    path: '/home',
    component: HomeView,
    // meta: { show: true },
  },
  {
    path: '/',
    name: "login",
    component: LoginView,
    // meta: { show: false },
  },
  {
    path: '/login',
    name: "login",
    component: LoginView,
    // meta: { show: false },
  },
  {
    path: '/bigsc',
    name: "bigsc",
    component: IframeBigsc,
    // meta: { show: false },
  },
  // {
  //   path: '/map',
  //   component: map,
  // },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
  // {
  //   path: '*',
  //   redirect: '/login'
  // }
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

export default router


