// 角色api层
import { get, post } from "../util/request"
export default {
    // 登录
    login(param) {
        let headers = 'application/x-www-form-urlencoded;charset=UTF-8'
        return post("/login", param, null, headers);
    },
    loginCode(param) {
        return get("/logincode", param);
    },
    getyuusercode(param) {
        return get("/getyuusercode", param);
    },
    testToken() {
        return post("/login/test-token");
    },
    search_dict(param) {
        return get('/search_dict', param)
    },
    // 用户
    search_user_by_username(param) {
        return get('/search_user_by_username', param)
    },
    get_usergroup(param) {
        return get('/get_usergroup', param)
    },
    add_usergroup(param) {
        return post('/add_usergroup', null, param)
    },
    delete_usergroup(param) {
        return post('/delete_usergroup', null, param)
    },
    get_usergroup_by_username(param) {
        return get('/get_usergroup_by_username', param)
    },
    get_usergroup_by_company(param) {
        return get('/get_usergroup_by_company', param)
    },
    get_yuusercompany_by_company(param) {
        return get('/get_yuusercompany_by_company', param)
    },
    get_yuusercompany_like_name(param) {
        return get('/get_yuusercompany_like_name', param)
    },
    add_yuusergroup(jsondata) {
        return post('/add_yuusergroup', jsondata)
    },
    delete_yuusergroup(jsondata) {
        return post('/delete_yuusergroup', jsondata)
    },
    get_yuuserland_by_company(param) {
        return get('/get_yuuserland_by_company', param)
    },
    add_yuuserland(jsondata, param) {
        return post('/add_yuuserland', jsondata, param)
    },
    get_yuusergroup_by_usergroup(param) {
        return get('/get_yuusergroup_by_usergroup', param)
    },
    add_landshare(jsondata, param) {
        return post('/addlandshare', jsondata, param)
    },
    delete_username_yuusercompany(param) {
        return post('/delete_username_yuusercompany', null, param)
    },
    get_yuusercompany_by_storehousecode(param) {
        return get('/get_yuusercompany_by_storehousecode', param)
    },
    update_yuusercompany(jsondata, param) {
        return post('/update_yuusercompany', jsondata, param)
    },
    // land模块
    search_storehousecode() {
        return get('/search_storehousecode')
    },
    predictingarea(jsondata) {
        return post('/predictingarea', jsondata);
    },
    get_land(param) {
        return get('/get_land', param);
    },
    get_landcoordinate(jsondata = null, param = null) {
        return post('/get_landcoordinate', jsondata, param)
    },
    search_storehouse_by_storehousecode(param) {
        return get('/search_storehouse_by_storehousecode', param);
    },
    delete_land(param) {
        return get('/delete_land', param);
    },
    update_land_landmsg(param) {
        return get('/update_land_landmsg', param);
    },
    update_land_landcoordinate(jsondata, param) {
        return post('/update_land_landcoordinate', jsondata, param);
    },
    get_yuuserland_by_storehousecode(param) {
        return get('/get_yuuserland_by_storehousecode', param);
    },
    update_yuuserland(jsondata, param) {
        return post('/update_yuuserland', jsondata, param);
    },
    // 天气模块
    get_weather(param) {
        return get('/shuxi_weather', param);
    },
    get_AccuWeather(param) {
        return get('/shuxi_AccuWeather', param);
    },
    get_querySolieSqTemp(param) {
        return get('/shuxi_querySolieSqTemp', param);
    },
    get_fieldInfoBi(param) {
        return get('/shuxi_fieldInfoBi', param);
    },
    getsoilfertility(param) {
        return get('/getsoilfertility', param);
    },
    get_hf_city_id(param) {
        return get('/get_hf_city_id', param);
    },
    // 流程模块
    get_processwork_by_storehousecode(param) {
        return get('/get_processwork_by_storehousecode', param);
    },
    get_processwork_by_processcode(param) {
        return get('/get_processwork_by_processcode', param);
    },
    get_processwork_by_currentcode(param) {
        return get('/get_processwork_by_currentcode', param);
    },
    get_processwork_by_beforecode(param) {
        return get('/get_processwork_by_beforecode', param);
    },
    get_processwork_by_plan(param) {
        return get('/get_processwork_by_plan', param);
    },
    //巡田
    get_fieldrecord(param) {
        return get('/getfieldrecord', param);
    },
    get_landclock(param) {
        return get('/getlandclock', param);
    },
    //文档管理相关
    //合同
    get_contract(param) {
        return get('/getcontract', param);
    },
    add_contract(jsondata) {
        return post("/addcontract", jsondata)
    },
    delete_contract(param) {
        return post("/deletecontract", null, param)
    },
    //单据
    get_document(param) {
        return get('/getdocument', param);
    },
    add_document(jsondata) {
        return post("/adddocument", jsondata)
    },
    delete_document(param) {
        return post("/deletedocument", null, param)
    },
    //票据
    get_cost(param) {
        return get('/getcost', param);
    },
    add_cost(jsondata) {
        return post("/addcost", jsondata)
    },
    delete_cost(param) {
        return post("/deletecost", null, param)
    },
    get_cost_by_storehousecode_type(param) {
        return get('/get_cost_by_storehousecode_type', param);
    },
    // 修改流程---storehousecode
    get_processmodel(param) {
        return get("/getprocessmodel", param)
    },
    get_processmodel_username(param) {
        return get("/getprocessmodel_username", param)
    },
    get_processmodel_createdby(param) {
        return get("/getprocessmodel_createdby", param)
    },
    get_processmodel_step(param) {
        return get("/getprocessmodel_step", param)
    },
    add_processmodel(jsondata) {
        return post("/addprocessmodel", jsondata)
    },
    delete_processmodel(param) {
        return get("/deleteprocessmodel", param)
    },
    update_processmodel(jsondata) {
        return post("/updateprocessmodel", jsondata)
    },
    // 种植方案
    getcropplan(param) {
        return get("/getcropplan", param)
    },
    addcropplan(jsondata) {
        return post("/addcropplan", jsondata)
    },
    deletecropplan(param) {
        return get("/deletecropplan", param)
    },
    updatecropplan(jsondata, param = null) {
        return post("/updatecropplan", jsondata, param)
    },
    // 资源
    getresource(param) {
        return get("/getresource", param)
    },
    getresource_by_croptype(param) {
        return get("/getresource_by_croptype", param)
    },
    addresource(jsondata) {
        return post("/addresource", jsondata)
    },
    deleteresource(param) {
        return get("/deleteresource", param)
    },
    // 流程创建
    create_process_model1(jsondata) {
        return post("/create_process_model1", jsondata)
    },
    update_process_model2(jsondata, param) {
        return post("/update_process_model2", jsondata, param)
    },
    update_process_model3(jsondata, param) {
        return post("/update_process_model3", jsondata, param)
    },
    update_process_model4(jsondata, param) {
        return post("/update_process_model4", jsondata, param)
    },
    update_process_model5(jsondata, param) {
        return post("/update_process_model5", jsondata, param)
    },
    update_process_model6(jsondata, param) {
        return post("/update_process_model6", jsondata, param)
    },
    update_process_model7(jsondata, param) {
        return post("/update_process_model7", jsondata, param)
    },
    // 统计
    Total_processwork_by_storehousecode(param) {
        return get("/Total_processwork_by_storehousecode", param)
    },
    Total_processwork_by_storehousecode_money(param) {
        return get("/Total_processwork_by_storehousecode_money", param)
    },
    get_shot_date_by_storehousecode_year(param) {
        return get("/get_shot_date_by_storehousecode_year", param)
    },
    get_ndvi_estimate_production_by_storehousecode_shotdate(param) {
        return get("/get_ndvi_estimate_production_by_storehousecode_shotdate", param)
    },
    // 电子围栏
    getfencecarmsg(param) {
        return get("/getfencecarmsg", param)
    },
    getfencewarning(param) {
        return get("/getfencewarning", param)
    },
    getfenceuserroute(param) {
        return get("/getfenceuserroute", param)
    },
    getfencelineall(param) {
        return get("/getfencelineall", param)
    },
    getallharvester(param) {
        return get("/getallharvester", param)
    },
    updatefencesetline(param) {
        return get("/updatefencesetline", param)
    },
    getfence(param) {
        return get("/getfence", param)
    },
    getlandvr_by_landcode(param) {
        return get("/getlandvr_by_landcode", param)
    },
    getlandvrurl_by_landcode(param) {
        return get("/getlandvrurl_by_landcode", param)
    },
    get_ys7(param) {
        return get("/get_ys7", param)
    },
    get_croptype(param) {
        return get("/get_croptype", param)
    },
    // 黄泛区专用农事上报接口
    getfanquorder(param) {
        return get("/getfanquorder", param)
    },
    addfanquorder(jsondata, param) {
        return post("/addfanquorder", jsondata, param)
    },
    deletefanquorder(jsondata, param) {
        return post("/deletefanquorder", jsondata, param)
    },
    getfanqureport(param) {
        return get("/getfanqureport", param)
    },
    get_fanqureport_by_titlecode(param) {
        return get("/get_fanqureport_by_titlecode", param)
    },
    addfanqureport(jsondata, param) {
        return post("/addfanqureport", jsondata, param)
    },
    deletefanqureport(jsondata, param) {
        return post("/deletefanqureport", jsondata, param)
    },
    getfanqusendname(param) {
        return get("/getfanqusendname", param)
    },
    getenclosure(param) {
        return get("/getenclosure", param)
    },
    getfanqufarmplan(param) {
        return get("/getfanqufarmplan", param)
    },
    getfanquorderno(param) {
        return get("/getfanquorderno", param)
    },
    getfanqureportno(param) {
        return get("/getfanqureportno", param)
    },
    get_fanqureportno_by_titlecode(param) {
        return get("/get_fanqureportno_by_titlecode", param)
    },
    addfanqureportno(jsondata, param) {
        return post("/addfanqureportno", jsondata, param)
    },
    deletefanqureportno(jsondata, param) {
        return post("/deletefanqureportno", jsondata, param)
    },
    getfanqufarmplanno(param) {
        return get("/getfanqufarmplanno", param)
    },
    // DATAV
    get_companydetailland(param) {
        return get("/get_companydetailland", param)
    },
    get_company_land_nongqing(param) {
        return get("/get_company_land_nongqing", param)
    },
    get_storehousecode_nongshili(param) {
        return get("/get_storehousecode_nongshili", param)
    },

    get_message(param) {
        return get("/get_message", param)
    },
    get_companyland_nongshili(param) {
        return get("/get_companyland_nongshili", param)
    },
    get_minjl_pointmsg(param) {
        return get("/get_minjl_pointmsg", param)
    },
    gettownmap(param) {
        return get("/gettownmap", param)
    },
    get_pingfen(param) {
        return get("/get_pingfen", param)
    },
    get_ndvi_by_storehousecode_and_year(param) {
        return get("/get_ndvi_by_storehousecode_and_year", param)
    },
    get_company_land_nongqing_byshuxi(param) {
        return get("/get_company_land_nongqing_byshuxi", param)
    },
    get_storehousecode_nongqing_byshuxi(param) {
        return get("/get_storehousecode_nongqing_byshuxi", param)
    },
    weather(cityname) {
        return get("/weather/" + cityname)
    },
    getwell(param) {
        return get("/getwell", param)
    },
    get_profit_by_storehousecode(param) {
        return get("/get_profit_by_storehousecode", param)
    },
    get_traceability_by_storehousecode(param) {
        return get("/get_traceability_by_storehousecode", param)
    },
    get_progress_by_crop(param) {
        return get("/get_progress_by_crop", param)
    },
    getfanqumidreport(param) {
        return get("/getfanqumidreport", param)
    },
    get_mj_forecast15days(param) {
        return get("/get_mj_forecast15days", param)
    },
    totalland_by_company(param) {
        return get("/totalland_by_company", param)
    },
    get_allmessage_by_storehousecode(param) {
        return get("/get_allmessage_by_storehousecode", param)
    },
    get_pingfencontent_by_storehousecode(param) {
        return get("/get_pingfencontent_by_storehousecode", param)
    },
    totalland_by_company_bigscreen(param) {
        return get("/totalland_by_company_bigscreen", param)
    },
    amounts1025(param) {
        return post("/amounts1025", null, param)
    },
    costtype1025(jsondata, param) {
        return post("/costtype1025", jsondata, param)
    },
    get_ndvi_by_storehousecode_and_year_new(param) {
        return get("/get_ndvi_by_storehousecode_and_year_new", param)
    },
    workjindu(param) {
        return get("/workjindu", param)
    },
    getcost1205(param) {
        return get("/getcost1205", param)
    }
}
