<template>
    <div class="body">
        <div class="leida-title">
            各农场
        </div>
        <div class="middle">
            <el-table :data="tableData" height="100%" border
                :header-cell-style="{ backgroundColor: 'transparent', color: 'white' }" class="userTable"
                style="width: 100%">
                <el-table-column type="index" label="序号" min-width="18%" align="center">
                </el-table-column>
                <el-table-column prop="storehousename" label="农场名称" min-width="18%" align="center">
                </el-table-column>
                <el-table-column label="面积" min-width="18%" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.landarea }} 亩
                    </template>
                </el-table-column>
                <el-table-column prop="landcount" label="地块" min-width="18%" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.landsum }} 块
                    </template></el-table-column>
                <el-table-column label="资产评级" min-width="18%" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.grade }}
                    </template>
                </el-table-column>
                <el-table-column label="详情" min-width="10%" align="center">
                    <template slot-scope="scope">
                        <span
                            :class="{ 'unclickable-text': isLastRow(scope.$index), 'clickable-text': !isLastRow(scope.$index) }"
                            @click="row_click(scope.row)">
                            {{ isLastRow(scope.$index) ? '/' : '点击' }}
                        </span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>

export default {
    name: '',
    components: {},
    props: ["point", "tableData"],
    data() {
        return {
        }
    },
    created() {
        this.login = this.$store.getters.get_login;
    },
    mounted() { },

    methods: {
        isLastRow(index) {
            return index === this.tableData.length - 1;
        },
        row_click(row) {
            if (!this.isLastRow(row.$index)) {
                console.log('Clicked on a non-last row. Navigating...');
                this.$router.push({
                    path: '/about',
                    query: {
                        code: row.storehousecode,
                        centercoordinate: row.centercoordinate,
                        pingji: row.grade,
                    }
                });
            } else {
                console.log('Clicked on the last row. Not navigating.');
            }
        },

    },
    watch: {

    }
}
</script>

<style lang='less' scoped>
.leida-title {
    width: 100%;
    height: 10%;
    color: #e4e4e4;
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    text-indent: 2%;
    line-height: 200%;
}

.unclickable-text {
    color: gray;
    /* 或者您希望的其他颜色 */
    cursor: default;
    /* 使用默认鼠标指针，表示不可点击 */
    text-decoration: none;
    /* 移除下划线效果 */
}

.body {
    height: 100%;
    width: 100%;
}

.clickable-text {
    color: aqua;
    /* 设置文本颜色为蓝色 */
    cursor: pointer;
    /* 显示手型光标表示可点击 */
    text-decoration: underline;
    /* 添加下划线效果 */
}

.middle {
    height: 90%;
    width: 100%;
    /* 可选的平滑过渡效果 */
}

.middle ::v-deep .el-table .el-table__body tr:hover {
    color: black;

    .clickable-text {
        color: black;
    }
}



.middle /deep/ .el-table,
.el-table__expanded-cell {
    background-color: transparent;
}

.middle /deep/ .el-table tr {
    background-color: transparent;
}

// .middle /deep/ .el-table--enable-row-transition .el-table__body td,
// .el-table .cell {
//     background-color: transparent;
// }

.el-table::before {
    //去除底部白线
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0px;
}

.userTable {
    /* 设置字体大小为 14px */
    font-size: 17px;
    color: white;

}

/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0px;
}

/deep/.el-table__body-wrapper::-webkit-scrollbar-thumb {
    border-radius: 2px;
    height: 50px;
    background: #eee;
}

/deep/.el-table__body-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.4);
}

/deep/.el-table th.gutter {
    display: none;
    width: 0
}

/deep/.el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
}

/deep/.el-table__body {
    width: 100% !important;
}
</style>