<template>
    <div class="mapdom">
        <div id="mapDiv" ref="mapDiv"
            v-bind:style="{ height: windowHeight * 0.875 + 'px', width: windowWidth * 0.5102 + 'px' }" class="mapdivclass">
        </div>
        <div class="tulidom">
            <div class="tuli">
                <el-checkbox-group v-model="checkedCities" style="color: white;" class="checkBoxClass">
                    <el-checkbox v-model="showWells" v-for="item in data" :label="item.label" :key="item.id"
                        @change="handleWellLabelClick(wellCoordinates)" style="color: white;">
                        {{ item.label }}
                    </el-checkbox>
                </el-checkbox-group>
                <el-checkbox-group v-if="checkedCities.includes('长势图')" v-model="showSatellite" style="color: white;"
                    class="sub-checkbox-group">
                    <div class="scroll-container">
                        <el-radio-group v-model="selectedDate" size="small">
                            <el-radio :label="dateItem" v-for="dateItem in date" :key="dateItem" style="color: white;">
                                {{ dateItem }}
                            </el-radio>
                        </el-radio-group>
                    </div>
                </el-checkbox-group>
            </div>
        </div>
    </div>
</template>

<script>
var map, handler, lineTool;
var zoom = 14;
var T = window.T;
var gyd;
export default {
    props: ["point"],
    data() {
        return {
            windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
            windowHeight: document.documentElement.clientHeight, //实时屏幕高度
            parentMap: [],
            childrenMap: [],
            parentAdcode: 410823,
            data: [{
                id: 1,
                label: '水井',
            }, {
                id: 2,
                label: '长势图',
                children: [{
                    id: 3,
                    label: '二级 2-1'
                },],
            }],
            defaultProps: {
                children: 'children',
                label: 'label',
            },
            checkedCities: [],
            selectedDate: null,
            login: null,
            showWells: false, // 控制水井显示的变量
            wellCoordinates: [],
            date: [],
            land: [],
            land1: [],
            landcoordinate: [],
            showDates: false,
            bounds3: [],
            showSatellite: false,
            satelliteLayer: null,
            point1: [],
            landpoint: [],
        };
    },
    created() {
        this.login = this.$store.getters.get_login;
        let me = this;
        this.$nextTick(function () {
            me.createmap();
        });
    },
    methods: {
        clearMap() {
            var lays = map.getOverlays(); //获取地图上所有的覆盖物
            for (var i = lays.length - 1; i >= 0; i--) {
                if (lays[i].options.alt == "map") {
                    map.removeOverLay(lays[i]); //从地图上移除。
                }
            }
        },
        createmap() {
            //创建自定义图层对象
            var lay = new T.TileLayer(window.img_w2, {
                minZoom: 4,
                maxZoom: 21,
            });
            var lay2 = new T.TileLayer(window.cia_w, {
                minZoom: 4,
                maxZoom: 21,
            });
            var config = {
                layers: [lay, lay2],
            };
            map = new T.Map("mapDiv", config);
            // map = new T.Map("mapDiv");
            map.addEventListener("click", this.MapClick);
            map.centerAndZoom(new T.LngLat(114.41460, 33.94081), zoom);
            // this.loadParent();
            this.get_land();
        },
        MapClick(e) {
            var pt = {
                latitude: e.lnglat.getLat(),
                longitude: e.lnglat.getLng(),
            };
            let pt2 = [e.lnglat.getLng(), e.lnglat.getLat()];
            let nofindmap = true;
            if (this.childrenMap.length == 0) {
                for (let i in this.parentMap) {
                    if (this.PointInPolyMap(pt2, this.parentMap[i])) {
                        nofindmap = false;
                    }
                }
            }
            else {
                for (let i in this.childrenMap) {
                    for (let j in this.childrenMap[i].polygons) {
                        if (this.PointInPolyMap(pt2, this.childrenMap[i].polygons[j])) {
                            this.loadParent(this.childrenMap[i].adcode);
                            nofindmap = false;
                        }
                    }
                }
            }
        },
        getMaxLngLat(data) {
            let lng = [];
            let lat = [];
            for (let i in data) {
                for (let j in data[i]) {
                    lng.push(data[i][j][0]);
                    lat.push(data[i][j][1]);
                }
            }
            const maxLng = Math.max(...lng); // 最大值
            const minLng = Math.min(...lng); // 最小值
            const maxLat = Math.max(...lat); // 最大值
            const minLat = Math.min(...lat); // 最小值
            return [minLng, minLat, maxLng, maxLat]
        },
        async loadParent(adcode = 100000) {
            this.clearMap();
            let parent = await this.$httpapi.gettownmap({ adcode: adcode });
            this.parentAdcode = parent.parent;
            let points = [];
            points.push(new T.LngLat(-360, 90));
            points.push(new T.LngLat(-360, -90));
            points.push(new T.LngLat(360, -90));
            points.push(new T.LngLat(360, 90));
            let points1 = [];
            points1.push(points);
            let pmap = [];
            this.setLngLatData(parent.landcoordinate, points1, pmap);
            map.centerAndZoom(new T.LngLat(parent.centroid[0], parent.centroid[1]));
            this.parentMap = pmap;
            // console.log(map.getBounds())
            let bounds = this.getMaxLngLat(pmap);
            let bounds2 = [new T.LngLat(bounds[0], bounds[1]), new T.LngLat(bounds[2], bounds[3])]
            map.setViewport(bounds2);
            this.childrenMap = [];
            for (let i in parent.child) {
                this.loadChildren(parent.child[i].adcode)
            }
        },
        //加地块
        async get_land() {
            console.log(this.login.company)
            let me = this;
            let param = {
                "company": this.login.company,
                // "storehousecode": this.login.storehousecode,
                "coordinateSystem": "wgs84"
            }
            this.land1 = await me.$httpapi.get_land(param);
            console.log(this.land1)
            this.land = await this.get_landcoordinate(this.land1);
            console.log(this.land)
            // map.centerAndZoom(new T.LngLat(this.land[0].x, this.land[0].y), zoom);
            this.get_selectedDate(this.land)
            this.getwell();
            return this.land
        },
        async get_selectedDate(land) {
            const data = await this.$httpapiuav.search_ndvi_by_year({
                year: 2023,
                landcode: land[0].landcode,
            });
            this.date = data.shot_date;
            this.selectedDate = this.date[0];
            this.getUavDate(land, this.selectedDate);
            console.log(this.selectedDate)
        },
        async get_landcoordinate(land) {
            let jsondata = [];
            for (let i = 0; i < land.length; i++) {
                jsondata.push(land[i].landcode)
            }
            let center = [0, 0];
            let param = {
                "coordinateSystem": "wgs84"
            }
            let landcoordinate = await this.$httpapi.get_landcoordinate(jsondata, param);
            this.landcoordinate = landcoordinate;
            for (let i = 0; i < land.length; i++) {
                for (let j = 0; j < landcoordinate.length; j++) {
                    if (land[i].landcode == landcoordinate[j].landcode) {
                        land[i]["landcoordinate"] = landcoordinate[j]["landcoordinate"]
                        center[0] = center[0] + landcoordinate[j]["landcoordinate"][0][0];
                        center[1] = center[1] + landcoordinate[j]["landcoordinate"][0][1];
                        break;
                    }
                }
            }
            center[0] = this.landcoordinate[0]["landcoordinate"][0][0];
            center[1] = this.landcoordinate[0]["landcoordinate"][0][1];
            map.centerAndZoom(new T.LngLat(center[1], center[0]), zoom);
            this.land = land;
            this.ploygon(land);
            return land
        },
        ploygon(land) {
            // 删除land
            var lays = map.getOverlays(); //获取地图上所有的覆盖物
            for (var i = lays.length - 1; i >= 0; i--) {
                if (lays[i].options.Type == "land") {
                    map.removeOverLay(lays[i]); //从地图上移除。
                }
            }
            for (let a = 0; a < land.length; a++) {
                var points = [];
                let landcenter = [999, -999, 999];
                for (let b = 0; b < land[a].landcoordinate.length; b++) {
                    points.push(new T.LngLat(land[a].landcoordinate[b][1], land[a].landcoordinate[b][0]));
                    landcenter[0] = landcenter[0] > land[a].landcoordinate[b][0] ? land[a].landcoordinate[b][0] :
                        landcenter[0];
                    landcenter[1] = landcenter[1] > land[a].landcoordinate[b][0] ? landcenter[1] : land[a]
                        .landcoordinate[b][0];
                    landcenter[2] = landcenter[2] > land[a].landcoordinate[b][1] ? land[a].landcoordinate[b][1] :
                        landcenter[2];
                }
                //创建面对象
                var polygon = new T.Polygon(points, {
                    color: "#FFFFFF",
                    weight: 1,
                    opacity: 1,
                    fillColor: "green",
                    fillOpacity: 0.3,
                    Type: "land",
                    village: land[a].village,
                    landid: land[a].id,
                });
                //向地图上添加面
                map.addOverLay(polygon);
                var latlng = new T.LngLat(landcenter[2], (landcenter[0] + landcenter[1]) / 2);
                var label = new T.Label({
                    text: land[a].landname,
                    position: latlng,
                    offset: new T.Point(-20, 0)
                });
                //创建地图文本对象
                // map.addOverLay(label);
            }
        },
        //加水井
        async getwell() {
            let me = this;
            let param = {
                "storehousecode": this.login.storehousecode,
            }
            let well = await me.$httpapi.getwell(param);
            // console.log(well)
            // 获取水井的坐标数据
            let wellCoordinates = well.map((item) => {
                const [latitude, longitude] = item.wellcoordinate.split(',').map(parseFloat);
                return {
                    latitude,
                    longitude,
                };
            });
            this.wellCoordinates = wellCoordinates; // 将数据存储在wellCoordinates数组中
            // console.log(wellCoordinates)
            // 更新地块，添加水井符号
            this.updateLandPlotWithWells(wellCoordinates);
        },
        updateLandPlotWithWells(wellCoordinates) {
            // 移除已有的水井标记
            var overlays = map.getOverlays();
            for (var i = overlays.length - 1; i >= 0; i--) {
                if (overlays[i].options.Type === 'well') {
                    map.removeOverLay(overlays[i]);
                }
            }
            // 如果showWells为true，则添加水井标记
            if (this.showWells && this.checkedCities.includes('水井')) {
                for (let i = 0; i < wellCoordinates.length; i++) {
                    let well = wellCoordinates[i];
                    let marker = new T.Marker(new T.LngLat(well.longitude, well.latitude), {
                        icon: new T.Icon({
                            iconUrl: '../../images/well60.jpg',
                            iconSize: new T.Point(18, 18),
                            iconAnchor: new T.Point(12, 12),
                        }),
                        Type: 'well',
                    });
                    map.addOverLay(marker);
                }
            }
        },
        handleWellLabelClick(wellCoordinates) {
            if (!this.showWells && this.checkedCities.includes('水井')) {
                this.showWells = true;
                this.updateLandPlotWithWells(wellCoordinates);
            } else {
                this.showWells = false;
                this.updateLandPlotWithWells(wellCoordinates);
            }
        },
        //加卫星
        async getUavDate(land, selectedDate) {
            var overlays = map.getOverlays();
            for (var i = overlays.length - 1; i >= 0; i--) {
                if (overlays[i].options.Type === 'satellite') {
                    map.removeOverLay(overlays[i]);
                }
            }
            if (this.showSatellite) {
                for (let i = 0; i < land.length; i++) {
                    try {
                        this.bounds3 = await this.getImgCornersList(land[i].landcoordinate)
                        // 将getSatelliteData方法移到获取卫星图像数据完成后
                        await this.getSatelliteData(land[i].landcode, selectedDate, this.bounds3);
                    } catch (error) {
                        console.error(error);
                    }
                }
            }
        },
        getImgCornersList(data) {
            let lng = [];
            let lat = [];
            for (let i in data) {
                lng.push(data[i][1]);
                lat.push(data[i][0]);
            }
            return [
                [Math.min(...lng), Math.min(...lat)],
                [Math.max(...lng), Math.max(...lat)]
            ]
        },
        async getSatelliteData(landcode, selectedDate, bounds3) {
            let filename1 = selectedDate + '_ndvi.png';
            try {
                var bd = new T.LngLatBounds(
                    new T.LngLat(bounds3[0][0], bounds3[0][1]),
                    new T.LngLat(bounds3[1][0], bounds3[1][1]));
                let imageUrl = "https://zyfudi.com:5720/api/downloadpng/" + landcode + "/" + filename1;
                const imageLayer = new T.ImageOverlay(imageUrl, bd, {
                    Type: 'satellite',
                });
                map.addOverLay(imageLayer);
            } catch (error) {
                console.error(error);
            }
        },

        getArrayDimension(arr) {
            let dimension = 1;
            let current = arr;
            while (Array.isArray(current[0])) {
                dimension++;
                current = current[0];
            }
            return dimension;
        },
        setLngLatData(data, pp, pmap) {
            if (this.getArrayDimension(data) == 2) {
                let p = [];
                for (let i in data) {
                    p.push(new T.LngLat(data[i][0], data[i][1]));
                }
                pp.push(p)
                pmap.push(data);
            }
            else if (this.getArrayDimension(data) > 2) {
                for (let i in data) {
                    this.setLngLatData(data[i], pp, pmap)
                }
            }
        },
        async loadChildren(adcode = 410000, isup = false) {
            let child = await this.$httpapi.gettownmap({ adcode: adcode });
            // console.log(child)
            let points1 = [];
            let pmap = [];
            this.setLngLatData(child.landcoordinate, points1, pmap);
            this.childrenMap.push({
                adcode: adcode,
                polygons: pmap
            })
            let polygon = null;
            if (isup) {
                polygon = new T.Polygon(points1, {
                    color: "red",
                    weight: 3,
                    opacity: 1,
                    fillColor: "#FFFFFF",
                    fillOpacity: 0,
                    adcode: adcode,
                    alt: "map",
                    select: true
                })
            }
            else {
                polygon = new T.Polygon(points1, {
                    color: "blue",
                    weight: 1,
                    opacity: 1,
                    fillColor: "#FFFFFF",
                    fillOpacity: 0,
                    adcode: adcode,
                    alt: "map",
                    select: false
                })
            }
            map.addOverLay(polygon);
            // polygon.addEventListener("click", this.overlay_style);
            polygon.addEventListener("mouseover", this.mouseover);
            polygon.addEventListener("mouseout", this.mouseout);
            // 移除默认的交通图层
            // map.removeLayer("TDTTraffic");

            const centerPoint = new T.LngLat(child.centroid[0], child.centroid[1]);
            // var label = new T.Label({
            //     text: child.name,
            //     position: centerPoint,
            //     offset: new T.Point(-9, 0)
            // });
            // //创建地图文本对象
            // map.addOverLay(label);
            // label.setOpacity(0)
        },
        overlay_style(e) {
            // console.log(e)
            // console.log(e.target.options)
            // this.loadParent(e.target.options.type);
        },
        mouseover(e) {
            // console.log(e)
            var lays = map.getOverlays(); //获取地图上所有的覆盖物
            for (var i = lays.length - 1; i >= 0; i--) {
                if (lays[i].options.alt != "map") {
                    continue
                }
                if (lays[i].options.adcode == e.target.options.adcode) {
                    lays[i].setColor('red');
                    lays[i].setWeight(3);
                    // if (e.target.options.select == false) {
                    //     lays[i].removeEventListener("mouseover", this.mouseover)
                    //     map.removeOverLay(lays[i]); //从地图上移除。
                    // }
                }
                else {
                    lays[i].setColor('blue');
                    lays[i].setWeight(1);
                }
            }
            // if (e.target.options.select == false) {
            //     this.loadChildren(e.target.options.adcode, true);
            // }
        },
        mouseout(e) {
            var lays = map.getOverlays(); //获取地图上所有的覆盖物
            for (var i = lays.length - 1; i >= 0; i--) {
                if (lays[i].options.adcode == e.target.options.adcode) {
                    lays[i].setColor('blue');
                    lays[i].setWeight(1);
                }
            }
        },
        PointInPolyMap(pt, poly) {
            for (var c = false, i = -1, l = poly.length, j = l - 1; ++i < l; j = i)
                ((poly[i][0] <= pt[0] &&
                    pt[0] < poly[j][0]) ||
                    (poly[j][0] <= pt[0] &&
                        pt[0] < poly[i][0])) &&
                    pt[1] <
                    ((poly[j][1] - poly[i][1]) *
                        (pt[0] - poly[i][0])) /
                    (poly[j][0] - poly[i][0]) +
                    poly[i][1] &&
                    (c = !c);
            return c;
        },
        hone(id, e) {
            console.log(id, e)
        },
        handleRadioChange(newvalue) {
            this.selectedDate = newvalue;
            this.getUavDate(this.land, this.selectedDate)
        },
        clearall() {
            var overlays = map.getOverlays();
            for (var i = overlays.length - 1; i >= 0; i--) {
                if (overlays[i].options.Type === 'well') {
                    map.removeOverLay(overlays[i]);
                }
            }
            var overlays = map.getOverlays();
            for (var i = overlays.length - 1; i >= 0; i--) {
                if (overlays[i].options.Type === 'land') {
                    map.removeOverLay(overlays[i]);
                }
            }
            var overlays = map.getOverlays();
            for (var i = overlays.length - 1; i >= 0; i--) {
                if (overlays[i].options.Type === 'satellite') {
                    map.removeOverLay(overlays[i]);
                }
            }
        },
    },
    mounted() {
        var that = this;
        // <!--把window.onresize事件挂在到mounted函数上-->
        window.onresize = () => {
            return (() => {
                window.fullHeight = document.documentElement.clientHeight;
                window.fullWidth = document.documentElement.clientWidth;
                that.windowHeight = window.fullHeight; // 高
                that.windowWidth = window.fullWidth; // 宽
            })();
        };
    },
    watch: {
        selectedDate(newvalue) {
            this.handleRadioChange(newvalue)
        },
        checkedCities(newvalue) {
            console.log(newvalue);
            if (newvalue.includes('长势图')) {
                console.log("in")
                this.showSatellite = true
                this.getUavDate(this.land, this.selectedDate)
            }
            else {
                console.log("out")
                this.showSatellite = false
                this.getUavDate(this.land, this.selectedDate)
            }
        },
        point(newVal) {
            this.landpoint = newVal;
            console.log(this.landpoint)
            // console.log(this.landpoint[1].centercoordinate)
            // const coordinatesArray = this.landpoint[1].centercoordinate.split(",");
            // this.point1[0] = coordinatesArray[0];
            // this.point1[1] = coordinatesArray[1];
            // console.log(this.point1[0])
            // map.centerAndZoom(new T.LngLat(this.point1[1], this.point1[0]), zoom);
            this.clearall();
            this.get_land();
        },
    },
};
</script>

<style scoped>
.mapdivclass {
    /* position: relative; */
    background-color: ghostwhite;
    z-index: 1;
    display: flex;
    flex-direction: row;
    z-index: 1;
    margin-bottom: 5px;
}

.mapdom {
    position: relative;
    margin-bottom: -2%;
}

.tulidom {
    position: absolute;
    bottom: 1%;
    left: 0%;
    z-index: 4;
}

.dates {
    right: 0%;
    top: 0%;
    height: 99%;
    width: 14%;
    position: absolute;
    z-index: 4;
    background-color: pink;
    overflow-y: hidden;

}

.tuli {
    width: 18%;
    height: 100%;
    background-color: rgba(12, 38, 89, 0.9);
}

.checkBoxClass {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.sub-checkbox-group {
    width: 380%;
    margin-left: 15%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.scroll-container {
    overflow: auto;
    max-height: 100px;
    width: 20%;

}

.scroll-container::-webkit-scrollbar {
    display: none;
}
</style>