<template>
	<div class="container">
		<div class="top">
			<div class="header">
				<el-select v-model="value" :placeholder="firstOptionLabel" class=" select" size="mini" @change="landchange">
					<el-option v-for="(child, index) in options" :key="index" :label="child.label"
						:value="child"></el-option>
				</el-select>
				<div class="h" @click="toggleFullScreen">
					粮田资产包平台
				</div>
				<div class="showTime">
					{{ currentTime }}
				</div>
			</div>
		</div>
		<div class="home">
			<div class="home-middle">
				<div class="home-middle-top">
					<dv-border-box-7>
						<div class="home-middle-top-top">
							<message :point="point" />
						</div>
						<div class="home-middle-top-bottom">
							<mymap :point="point" />
						</div>
					</dv-border-box-7>
				</div>
			</div>
			<div class="home-right">
				<div class="home-right-box">
					<div class="dv-border-box-10">
						<div class="leida-title">
							主体概况
						</div>
						<div class="home-right-box-box">
							<div class="dalei">
								<gaikuang :point="point" :tableData="tableData" />
							</div>
						</div>
					</div>
				</div>
				<div class="home-right-box">
					<div class="dv-border-box-10">
						<everyfarm :point="point" :tableData="tableData" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mymap from './mymap/mymap.vue';
import everyfarm from './everyfarm/everyfarm.vue';
import message from './message/message.vue';
import gaikuang from './zhutigaikuang/gaikuang.vue';
export default {
	name: 'HomeView',
	data() {
		return {
			options: [],
			value: [],
			code: [],
			currentTime: '',
			process: null,
			point: null,
			tableData: [],
		};
	},
	components: {
		mymap,
		message,
		gaikuang,
		everyfarm
	},
	computed: {
		firstOptionLabel() {
			if (this.company) {
				for (let i = 0; i < this.options.length; i++) {
					if (this.company === this.options[i].value) {
						this.farmname = this.options[i].label;
						this.point = this.options[i];
						return this.farmname;
					}
				}
			} else if (this.options.length > 0) {
				const firstOption = this.options[0];
				console.log(this.options[0]);
				this.farmname = firstOption.label;
				let login = this.$store.getters.get_login;
				login.company = this.options[0].value;
				this.point = this.options[0];
				this.totalland_by_company_bigscreen();
				return firstOption.label;
			}
			return '';
		},
	},
	created() {
		let login = this.$store.getters.get_login;
		console.log(login);
		this.search_storehousecode()
		this.totalland_by_company_bigscreen()
		this.chuanshu()
		if (this.options.length > 0) {
			this.value = this.options[0];
		}
	},
	methods: {
		chuanshu() {
			let paramValue = this.$route.query;
			this.company = paramValue.company
		},
		async search_storehousecode() {
			let data = await this.$httpapi.search_storehousecode();
			this.code = data;
			console.log(this.code)
			this.options = this.code.map(farm => {
				return {
					value: farm.value,
					label: farm.label,
					children: farm.children.map(child => {
						return {
							value: child.value,
							label: child.label,
						};
					}),
				};
			});
		},
		landchange(newVal) {
			console.log(newVal)
			console.log(newVal.value)

			this.$router.push({ path: '/home', query: { company: newVal.value } });
			let login = this.$store.getters.get_login;
			// login.storehousecode = newVal[1].storehousecode;
			// login.storehousename = newVal[1].storehousename;
			login.company = newVal.value;
			// login.city = newVal[1].city;
			this.$store.commit('set_login', login);
			this.point = newVal;
		},

		async totalland_by_company_bigscreen() {
			this.login = this.$store.getters.get_login;
			let param = {
				"company": this.login.company || this.company,
			}
			let totalland = await this.$httpapi.totalland_by_company_bigscreen(param);
			console.log(totalland)
			this.tableData = totalland;
		},
		toggleFullScreen() {
			if (document.fullscreenElement) {
				// 当前处于全屏模式，退出全屏
				if (document.exitFullscreen) {
					document.exitFullscreen();
				} else if (document.mozCancelFullScreen) {
					document.mozCancelFullScreen();
				} else if (document.webkitExitFullscreen) {
					document.webkitExitFullscreen();
				} else if (document.msExitFullscreen) {
					document.msExitFullscreen();
				}
			} else {
				// 当前不处于全屏模式，进入全屏
				const element = document.documentElement;
				if (element.requestFullscreen) {
					element.requestFullscreen();
				} else if (element.mozRequestFullScreen) {
					element.mozRequestFullScreen();
				} else if (element.webkitRequestFullscreen) {
					element.webkitRequestFullscreen();
				} else if (element.msRequestFullscreen) {
					element.msRequestFullscreen();
				}
			}
		},
		updateTime() {
			const updateTime = () => {
				const dt = new Date();
				const y = dt.getFullYear();
				const mt = dt.getMonth() + 1;
				const day = dt.getDate();
				const h = dt.getHours();
				const m = dt.getMinutes();
				const s = dt.getSeconds();
				this.currentTime =
					'当前时间：' +
					y +
					'年' +
					mt +
					'月' +
					day +
					'日' +
					h +
					'时' +
					m +
					'分' +
					s +
					'秒';
			};
			updateTime(); // 立即更新时间
		},
	},
	mounted() {
		this.updateTime(); // 立即更新时间
		this.timer = setInterval(this.updateTime, 1000); // 1000毫秒即1秒更新一次
	},
	watch: {
		point() {
			this.totalland_by_company_bigscreen()
		}
	}
}

</script>

<style lang="less" scoped>
.container {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	// background: url("../../public/images/bg.png");
}

.header {
	background: url("../../public/images/header.png") no-repeat;
	background-size: 100% 150%;
	display: flex;
	position: absolute;
	/* 添加绝对定位 */
	top: 0;
	/* 调整 top 属性来控制位置 */
	left: 0;
	/* 调整 left 属性来控制位置 */
	width: 100%;
	/* 设置宽度为父元素的100% */
	height: 100%;
	/* 设置高度为父元素的100% */
	align-items: center;
	/* 垂直居中 */
	justify-content: center;
	/* 水平居中 */
}

.h {
	color: white;
	font-size: 24px;
	font-family: "微软雅黑", sans-serif;
}


.top {
	height: 5%;
	width: 100%;
	text-align: center;
	position: relative;
}

.showTime {
	position: absolute;
	right: 1vh;
	top: 0;
	line-height: 5vh;
	color: rgba(255, 255, 255, 0.7);
	font-size: 2vh;
}

.select {
	position: absolute;
	width: 9.5%;
	left: 0%;
	color: blue;

}

::v-deep .el-input.el-input--suffix {

	// 修改背景颜色、字体颜色、边框、宽高
	.el-input__inner {
		background: rgba(12, 38, 89, 0.7);
		color: #b5c5d4;
	}

}


.home {
	display: flex;
	height: 95%;
	width: 100%;
	/* 设置内容区域占据剩余空间 */
}

.home-left {
	width: 9.7%;
	height: 100%;
	/* 设置左侧div的宽度 */
	// background-color: red;
}

.home-left-top {
	height: 20%;
}

.home-left-bottom {
	height: 80%;
	width: 100%;
}

.home-middle {
	height: 100%;
	width: 51.3%;
	display: flex;
	flex-direction: column;
	/* 设置中间div占据剩余空间 */
	// background-color: green;
}

.home-middle-top {
	height: 100%;
	/* 设置上方盒子的高度 */
	// background-color: orange;
}

.home-right {
	width: 50%;
	height: 100%;
	/* 设置右侧div的宽度 */
	display: flex;
	/* 嵌套的Flexbox布局 */
	flex-direction: column;
	/* 将右侧div改为垂直方向排列 */
	justify-content: space-around;

}


.home-right-box {
	height: 49%;
	width: 100%;
	/* 将每个右侧子盒子平分高度 */
	// background-color: pink;
	display: flex;
	flex-direction: row;
}

.dv-border-box-10 {
	height: 99%;
	width: 99%;
	background-color: rgba(12, 38, 89, 0.7);
}

.home-right-box-box {
	display: flex;
	height: 70%;
}

.home-right-box-box-right {
	width: 80%;
	height: 85%;
}

.qihou {
	height: 90%;
	width: 100%;
}

.home-right-box-box-left {
	width: 20%;
	display: flex;
	// background-color: aqua;
	margin-left: 2%;
}

.leida-title {
	height: 13%;
	color: #e4e4e4;
	font-size: 20px;
	font-weight: bold;
	text-align: left;
	text-indent: 2%;
	line-height: 200%;
}



.dalei {
	height: 90%;
	width: 100%;
	display: flex;
}

.leida_class {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.home-middle-top-top {
	width: 100%;
	height: 6%;

}

.home-middle-top-bottom {
	width: 100%;
	height: 94%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.zhangshi_class {
	width: 100%;
	height: 77%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
</style>