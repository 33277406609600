import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);
//引入路由
import router from './router'
//引入仓库
import store from './store'
import dataV from '@jiaminghi/data-view'

import 'leaflet/dist/leaflet.css'
// 引入Leaflet对象 挂载到Vue上，便于全局使用，也可以单独页面中单独引用
import * as L from 'leaflet'
import 'leaflet.pm'
import 'leaflet.pm/dist/leaflet.pm.css'

import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);


Vue.config.productionTip = false;
Vue.L = Vue.prototype.$L = L

/* leaflet icon */
delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})




// import * as echarts from 'echarts';
// import china from 'echarts/map/json/china.json';
// echarts.registerMap('china', china);
// Vue.prototype.$echarts = echarts;

import echarts from 'echarts'
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false
Vue.use(dataV)

const app = new Vue({
  render: h => h(App),
  //注册路由：KV一致省略V
  router,
  store,
})

// http接口API集中管理引入部分
import landapi from './api/landapi'
// Vue.use(httpApi, app)
Vue.prototype.$httpapi = landapi

// http接口API集中管理引入部分
import uavapi from './api/uavapi'
// Vue.use(httpApi, app)
Vue.prototype.$httpapiuav = uavapi


import axios from 'axios'
Vue.prototype.$http = axios
import { BASE_URL } from "./config/index"
Vue.prototype.BASE_URL = BASE_URL;
app.$mount('#app')