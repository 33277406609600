// 定义接口根路径
export default {
    dev: {
        name: "开发环境接口",
        url: "https://bj.zyfudi.com:5789/yzt/api"
    },
    uavdev: {
        name: "开发环境接口--卫星",
        url: "https://zyfudi.com:5720/api"
    }
}