<template>
  <div class="container">
    <div id="app">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {}
}
</script>

<style>
.container {
  background-image: url("../public/images/bg.png");
  background-size: 100%, 100%;
  width: 100vw;
  height: 100vh;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
