<template>
    <div class="leida">
        <div class="leida-top">
            <dv-border-box-12>
                &nbsp;&nbsp;&nbsp;&nbsp;<b>|</b> 名称：<div class="jiekou1">{{ this.landpoint }}</div>
            </dv-border-box-12>
        </div>
        <div class="leida-bottom">
            <div class="leida-left">
                <dv-border-box-12>
                    <br /><b>|</b> 资产评级<br />
                    <div class="jiekou">{{ cropgrowth1.grade }}</div>
                </dv-border-box-12>
                &nbsp;
                <dv-border-box-12>
                    <br /><b>|</b> 村集体<br />
                    <div class="jiekou">{{ cropgrowth.length - 1 }}个</div>
                </dv-border-box-12>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div class="leida-right">
                <dv-border-box-12>
                    <br /><b>|</b> 农田面积<br />
                    <div class="jiekou">{{ (Math.floor(cropgrowth1.landarea)).toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                        ",")
                    }}亩</div>
                </dv-border-box-12>
                &nbsp;
                <dv-border-box-12>
                    <br /><b>|</b> 地块<br />
                    <div class="jiekou">{{ cropgrowth1.landsum }}块</div>
                </dv-border-box-12>
            </div>
        </div>
    </div>
</template>

<script>
const echarts = require('echarts');
var myChart;
export default {
    props: ["point", "tableData", "farmname"],
    data() {
        return {
            cropgrowth: [],
            cropgrowth1: [],
            login: null,
            landpoint: '',
            name: []
        };
    },
    created() {
        this.login = this.$store.getters.get_login;
    },
    mounted() {
    },
    methods: {
        async totalland_by_company() {
            console.log(this.tableData)
            let cropgrowth = this.tableData;
            this.cropgrowth = cropgrowth;
            let cropgrowth1 = cropgrowth[cropgrowth.length - 1]
            this.cropgrowth1 = cropgrowth1;
        },
    },
    watch: {
        tableData(newVal) {
            this.cropgrowth = false;
            this.totalland_by_company()
        },
        point(newVal) {
            this.landpoint = newVal.label;
            console.log(this.landpoint)
        },
        farmname(newVal) {
            this.landpoint = newVal

        }
    },
};
</script>

<style lang="less" scoped>
.leida {
    width: 100%;
    height: 100%;
}

.leida-top {
    width: 58%;
    height: 30%;
    display: flex;
    color: aqua;
    margin-left: 20%;
    text-align: left;
    white-space: nowrap;
    /* 防止换行 */

}

.leida-top b {
    color: white;
}

.leida-bottom {
    width: 100%; //420px;
    height: 70%; //270px;
    display: flex;
}

.pingfen {
    width: 100%;
    height: 120%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    left: 2%;
}



.leida-left {
    height: 60%;
    width: 25%;
    margin-left: 20%;
    color: aqua;
    margin-top: 2%;

}

.leida-left b {
    color: white;
}

.leida-right b {
    color: white;
}

.leida-right {
    height: 60%;
    width: 25%;
    color: aqua;
    margin-top: 2%;
}

.jiekou {
    font-size: 20px;
    color: white;
    line-height: 250%;

}

.jiekou1 {
    font-size: 17px;
    color: white;
    line-height: 330%;
    display: inline-block;
    /* 或者使用 inline，根据实际需求选择 */
}
</style>
