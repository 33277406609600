<template>
  <div class="body">
    <van-notice-bar color="#fff" background="#001938" left-icon="volume-o" :scrollable="false">
      <van-swipe vertical class="notice-swipe" :autoplay="3000" :touchable="false" :show-indicators="false">
        <van-swipe-item v-for="(item, index) in news" :key="index">{{ item.createdtime }} {{ item.content
        }}</van-swipe-item>
      </van-swipe>
    </van-notice-bar>
  </div>
</template>

<script>

export default {
  name: '',
  components: {},
  props: ["point"],
  data() {
    return {
      news: [],
      landpoint: [],
      login: null,
    };
  },
  created() { },
  mounted() {
    this.login = this.$store.getters.get_login;
    this.get_message();
  },
  methods: {
    async get_message() {
      let param = {
        PROVINCE: '河南省',
        company: this.login.company,
      }
      let data = await this.$httpapi.get_message(param);
      this.news = data
      console.log(data)
    },
  },
  watch: {
    point(newVal) {
      this.landpoint = newVal;
      console.log(this.landpoint)
      this.get_message();
    },
  },
}
</script>

<style scoped>
.notice-swipe {
  height: 40px;
  line-height: 40px;
  width: 100%;
}

.body {
  width: 100%;
  height: 100%;
  margin-left: -0.7%;
  margin-top: -0.5%;
}

.van-notice-bar {
  left: 5px;
  top: 4px;
  width: 100%;
}

:deep(.van-notice-bar__content.van-ellipsis) {
  min-width: 100%;
  max-width: 100%;
}
</style>
