import axios from 'axios';
import QS from 'qs';
// import store from '../store/index'
// 导入BASE_URL
import { BASE_URL, UAV_URL } from "../config/index"

// 请求超时时间
axios.defaults.timeout = 10000;

// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 请求拦截器
axios.interceptors.request.use(
    config => {
        // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
        var token = sessionStorage.getItem("token");
        if (token) { }
        else {
            let login = sessionStorage.getItem("login");
            if (login) {
                login = JSON.parse(login)
                token = login.token;
                sessionStorage.setItem("token", login.token);
            }
        }
        token && (config.headers.Authorization = token);
        return config;
    },
    error => {
        console.log(error)
        return Promise.error(error);
    })
// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    if (response.status == 401) {
        // this.$router.push("/login");
    }
    // 对响应数据做点什么
    return response;
}, function (error) {
    // 对响应错误做点什么
    console.log(error)
    return Promise.reject(error);
});

// 系统的请求
/** 
 * get方法，对应get请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function get(url, params = null, headers = 'application/json;charset=UTF-8') {
    axios.defaults.baseURL = BASE_URL;
    axios.defaults.headers.get['Content-Type'] = headers;
    if (params == null) {
        return new Promise((resolve, reject) => {
            axios.get(url)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err.data)
                })
        });
    }
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}
/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */

export function post(url, jsondata = null, params = null, headers = 'application/json;charset=UTF-8') {
    axios.defaults.baseURL = BASE_URL;
    let newUrl = url + (params ? "?" + QS.stringify(params) : "")
    axios.defaults.headers.post['Content-Type'] = headers;
    if (jsondata != null) {
        if (headers == 'application/x-www-form-urlencoded;charset=UTF-8') {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8',
                    axios.post(url, QS.stringify(jsondata))
                        .then(res => {
                            resolve(res.data);
                        })
                        .catch(err => {
                            reject(err.data)
                        })
            });
        }
        return new Promise((resolve, reject) => {
            axios.post(newUrl, jsondata)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err.data)
                })
        });
    }
    else {
        return new Promise((resolve, reject) => {
            axios.post(newUrl)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err.data)
                })
        });
    }
}
// 无人机/卫星遥感的请求
/** 
 * get方法，对应get请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function getuav(url, params = null, headers = 'application/json;charset=UTF-8') {
    axios.defaults.baseURL = UAV_URL;
    axios.defaults.headers.get['Content-Type'] = headers;
    // console.log(axios.defaults.headers)
    if (params == null) {
        return new Promise((resolve, reject) => {
            axios.get(url)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err.data)
                })
        });
    }
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}
/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */

export function postuav(url, jsondata = null, params = null, headers = 'application/json;charset=UTF-8') {
    axios.defaults.baseURL = UAV_URL;
    let newUrl = url + (params ? "?" + QS.stringify(params) : "")
    axios.defaults.headers.post['Content-Type'] = headers;
    if (jsondata != null) {
        if (headers == 'application/x-www-form-urlencoded;charset=UTF-8') {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8',
                    axios.post(url, QS.stringify(jsondata))
                        .then(res => {
                            resolve(res.data);
                        })
                        .catch(err => {
                            reject(err.data)
                        })
            });
        }
        return new Promise((resolve, reject) => {
            axios.post(newUrl, jsondata)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err.data)
                })
        });
    }
    else {
        return new Promise((resolve, reject) => {
            axios.post(newUrl)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err.data)
                })
        });
    }
}


