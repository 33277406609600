<template>
    <div class="abstract">
        <div class="abstract-inner">
            <iframe width="512" height="288" src="/#/login" frameborder="0" allowfullscreen="allowfullscreen"></iframe>
        </div>
    </div>
</template>
<script>
export default {
    name: "iframebigsc",
    components: {},
    data() {
        return {

        };
    },
    created() {

    },
    methods: {
    },
    mounted() {
    },
    destroyed() {

    },
    watch: {

    },
};
</script>
<style scoped>
.abstract {

    min-width: 1600px;

}

.abstract-inner {
    position: relative;
    padding-bottom: 56.25%;
    /*288/512=0.5625*/
    height: 0;
    overflow: hidden;
}

.abstract-inner iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>