import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    login: null,
    data: {}
  },
  getters: {
    get_login: state => {
      let login = state.login;
      if (login == null) {
        login = sessionStorage.getItem("login");
        if (login) {
          login = JSON.parse(login);
        }
        else {
          login = null;
        }
        state.login = login;
      }
      return login
    },
    get_data(state) {
      let data = state.data;
      return data
    }
  },
  mutations: {
    set_login(state, data) {
      state.login = data;
      sessionStorage.setItem('login', JSON.stringify(data));
      if (data.token) {
        sessionStorage.setItem('token', data.token);
      }
    },
    set_data(state, data) {
      state.login = data;
    }
  },
  actions: {},
  modules: {}
})
